import { Injectable, EventEmitter, Inject, PLATFORM_ID, Renderer2, Optional, RendererFactory2 } from '@angular/core'
import { Router } from '@angular/router'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Title, Meta, MetaDefinition } from '@angular/platform-browser'

import {TransferState, makeStateKey} from '@angular/platform-browser';

// environment
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common'
import { environment } from '../../../environments/environment'
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  // static data
  baseUrl: string = environment.baseUrl
  apiUrl: string = this.baseUrl + '/api/v1/'
  galleryUrl: string = environment.galleryUrl;
  templateUrl: string = `${environment.galleryUrl}${environment.name == '(PROD)' ? `template_${environment.template_id}_v2`: 'template_dev'}`
  emptyImage: string = `${this.templateUrl}/assets/images/empty-image.svg`

  //Informacion del sitio.
  website: any = {
    id: null,
    title: null,
    description: null,
    image: null,
    url: null,
    favicon: null,
    direction: null,
    currency: '$',
    decimals: 2,
    country_id: null,
    whatsapp_checkout_mode: false,
    whatsapp_checkout_pro: false,
  }

  header: any = {
    visible_message: 0,
    base_visible_message: 0, //Variable base
    message_text: null,
    message_link: null,
    type_background_header_id: 3,
    base_type_background_header_id: 3, //Variable base
    is_fixed: false,
    visible_searchbar: false,
    visible_session: false,
    above_carrousel: true
  }

  footer: any = {
    methodsPayment: [],
    methodsShipment: []
  }

  menu: any = [];

  //Estilos
  styles: any = {
    color_primary: "#000000",
    color_contrast_primary: "#ffffff",
    color_secondary: "#1c1c1c",
    color_contrast_secondary: "#ffffff",
    color_text_primary: "#000000",
    color_background: "#000000",
    color_header_bg: "#333333",
    color_header_text: "#ffffff",
    color_header_message_bg: "#333333",
    color_header_message_text: "#333333",
    color_footer_bg: "#333333",
    color_footer_text: "#ffffff",
  }

  styleProduct: any = {
    aspect_ratio: 'auto',
    show_sku: false,
    show_buy_now: false,
    show_installments: false,
    show_methods_payments: false,
    text_align: 'center',
    show_border: true,
    object_fit: 'contain',
    type_product_subtext_id: 1,
    subtext_custom: "",
    type_product_text_price_id: 1
  }

  options: any = {
    chat: {
      status: 0,
      data: {
        chat_facebook_url: null,
        chat_whatsapp_url: null,
        chat_instagram_url: null
      }
    },
    datafiscal: {
      status: 0,
      data: {
        datafiscal_codigo: null,
      }
    },
    contact: {
      status: 0,
      data: {
        title: null,
        direction: null,
        phone: null,
        email: null,
        schedule: null
      }
    },
    form_contact: {
      status: 0,
      data: {}
    },
    blog:{
      status: 0,
      data: []
    },
    google_analytics:{
      status: 0,
      data: null
    },
    facebook_pixel:{
      status: 0,
      data: null
    },
    shipment: {
      status: 1,
      data: []
    },
    shipment_alert: {
      status: 0,
      data: null,
    },
    payment_method: {
      status: 1,
      data: []
    },
    checkout: {
      only_buy_client: 0,
      only_show_price_client: 0,
      price_min_buy: null,
      required_dni: 1,
      required_phone: 1,
      required_note: 1,
      required_company: 0
    },
    scripts: {
      status: 0,
      data: {
        head: null,
        body: null,
      },
    },
    wholesale: {
      status: 0,
      data: {
      }
    }
  };

  methodsPayments: any = [];

  social = {
    twitter: null,
    facebook: null,
    pinterest: null,
    instagram: null,
    youtube: null,
    tiktok: null,
  };

  installments: number = 0;

  logging = false; //EXISTE EL LOGGING O NO?

  sections = [];

  // memory state
  ls: any
  isLogged: boolean = false;
  showPrices: boolean = true;
  loading: boolean = false;
  isMenuOpen: boolean = false;
  headerSize: number;
  page: string
  profile: any
  favorites: any
  favoritesJson: any
  payments: any
  nextUrl: any = {
    url: null,
    timestamp: null
  }

  listProductInCart = {};
  methodShipment = {};
  totalPriceInCart = 0;
  totalWeightInCart = 0;
  totalVolumeInCart = 0;

  countShowOpenCartEmmiter = 0;
  loginBox = false;

  listBox = false;
  listBoxId = 0;

  installmentsBox = false;
  installmentsPrice = 0;

  regretBox = false;

  // global events
  signinRequired: EventEmitter<boolean> = new EventEmitter();
  openCart: EventEmitter<boolean> = new EventEmitter();
  onSelectShipment: EventEmitter<any> = new EventEmitter();
  onUpdateListShipment: EventEmitter<any> = new EventEmitter();
  onLoadingListShipment: EventEmitter<any> = new EventEmitter();
  onUpdateHeader: EventEmitter<any> = new EventEmitter();
  onUpdateSection: EventEmitter<number> = new EventEmitter();

  mobile: boolean = false;
  isBrowser = false;
  isServer;
  isBackoffice = false;

  css_external: any;

  //Render2
  private renderer: Renderer2;

  constructor(
    private _router: Router,
    private _title: Title,
    private _meta: Meta,
    private _state:TransferState,
    private _http: HttpClient,
    private _renderFactory: RendererFactory2,
    private _deviceService: DeviceDetectorService,
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) platformId: Object,
    @Optional() @Inject("hostname") private hostname: string,
  ) {

    this.renderer = _renderFactory.createRenderer(null,null);
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);

    if(this.isBrowser){
      this.mobile = this.isMobile();
    }

    if(hostname && this.isServer){
      this.baseUrl = "https://"+hostname;
      this.apiUrl = this.baseUrl + '/api/v1/';
    }

  }

  setWebsiteInfo(website){
    this.website = {...this.website, ...website};
    this.setTitle(this.website.title);
    this.setPageDescription(this.website.description);
    this.setPageImage(this.website.logo);
  }

  setStyles(
    styles = {
      color_primary: "#000000",
      color_contrast_primary: "#ffffff",
      color_secondary: "#1c1c1c",
      color_contrast_secondary: "#ffffff",
      color_text_primary: "#333333",
      color_background: "white",
      color_header_bg: "#333333",
      color_header_text: "#ffffff",
      color_header_message_bg: "#ffffff",
      color_header_message_text: "#333333",
      color_footer_bg: "#333333",
      color_footer_text: "#ffffff",
    }
  ){

    this.styles = {...this.styles, ...styles};
    this.updateMeta({name: 'msapplication-TileColor', content: this.styles.color_primary }, 'name="msapplication-TileColor"');
    this.updateMeta({name: 'theme-color', content: this.styles.color_primary }, 'name="theme-color"');

    for(let style in this.styles){
      if(this.isServer){
        this.renderer.setStyle(this.doc.documentElement, `--${style}`, styles[style]);
      }else{
        document.documentElement.style.setProperty(`--${style}`, styles[style]);
      }
    }

  }

  setStyleProduct(
    styles = {
      aspect_ratio: 'auto',
      show_sku: false,
      show_buy_now: false,
      show_installments: false,
      show_methods_payments: false,
      text_align: 'center',
      show_border: true,
      type_product_subtext_id: 1
    }
  ){

    this.styleProduct = {...this.styleProduct, ...styles};

    for(let style in this.styleProduct){
      if(typeof this.styleProduct[style] != 'boolean'){
        if(this.isServer){
          this.renderer.setStyle(this.doc.documentElement, `--product-${style}`, this.styleProduct[style]);
        }else{
          document.documentElement.style.setProperty(`--product-${style}`, this.styleProduct[style]);
        }
      }
    }
  }

  setHeader(header){
    this.header = {...this.header, ...header};
    this.onUpdateHeader.emit();
  }

  updateSizeHeader(size){
    this.headerSize = size;
  }

  setFavicon(imageUrl){

    const iconApple:HTMLLinkElement = this.renderer.createElement('link');
    const icon:HTMLLinkElement = this.renderer.createElement('link');

    icon.href = `${imageUrl}?class=xs`;
    iconApple.href = `${imageUrl}?class=xs`;

    icon.rel = 'shortcut icon';
    iconApple.rel = 'apple-touch-icon'

    icon.type = "image/x-icon";
    iconApple.type = "image/x-icon";

    if(this.isServer){
      this.renderer.appendChild(this.doc.head, iconApple);
      this.renderer.appendChild(this.doc.head, icon);
    }else{
      document.head.appendChild(iconApple);
      document.head.appendChild(icon);
    }

  }

  setFontStyle(font_styles){

    const linkPrimary:HTMLLinkElement = this.renderer.createElement('link');
    const linkSecondary:HTMLLinkElement = this.renderer.createElement('link');

    linkPrimary.href = font_styles.primary.url;
    linkPrimary.rel = "stylesheet";
    linkSecondary.href = font_styles.secondary.url;
    linkSecondary.rel = "stylesheet";

    // this.renderer.setAttribute(linkPrimary, 'rel', '');
    // this.renderer.setAttribute(linkSecondary, 'rel', '');

    if(this.isServer){
      this.renderer.appendChild(this.doc.head, linkPrimary);
      this.renderer.appendChild(this.doc.head, linkSecondary);
      this.renderer.setStyle(this.doc.documentElement, `--font_primary`, font_styles.primary.css_style);
      this.renderer.setStyle(this.doc.documentElement, `--font_secondary`, font_styles.secondary.css_style);
    }else{
      document.head.appendChild(linkPrimary);
      document.head.appendChild(linkSecondary);
      document.documentElement.style.setProperty(`--font_primary`, font_styles.primary.css_style);
      document.documentElement.style.setProperty(`--font_secondary`, font_styles.secondary.css_style);
    }

  }

  addMetatags() {

    const linkCanonical: HTMLLinkElement = this.renderer.createElement('link');
    linkCanonical.rel = "canonical";
    linkCanonical.href = "https://"+ this.website.url + this._router.url;
    this.renderer.appendChild(this.doc.head, linkCanonical);

    this._meta.addTags([
      {
        property: 'og:title',
        content: this.website.title
      },
      {
        property: 'og:url',
        content: this.website.url
      },
      {
        property: 'og:site_name',
        content: this.website.title
      },
      {
        property: 'og:description',
        content: this.website.description
      },
      {
        name: 'twitter:card',
        content: 'summary_large_image'
      },
      {
        name: 'twitter:title',
        content: this.website.title
      },
      {
        name: 'twitter:description',
        content: this.website.description
      }
    ]);

  }

  addScript(url, html = '', async = true, defer = true){
    const script = this.renderer.createElement('script');
    url && (script.src = url);
    script.innerHTML = html;
    script.async = async;
    script.defer = defer;
    this.renderer.appendChild(this.doc.head, script);
    return script;
  }

  updateCSSExternal(css?){
    //Si ya hay un css instalado
    if(this.css_external){
      this.css_external.innerHTML = css;
    }else{
      //Si es el servidor genero el style
      if(this.isServer){
        const style = this.renderer.createElement('style');
        style.type = "text/css";
        style.innerHTML = css;
        style.id = 'css_external';
        this.renderer.appendChild(this.doc.head, style);
        this.css_external = style;
      }else{
        //Si es el browser en la primera visualizacion
        this.css_external = this.doc.getElementById('css_external');

        //Esto es por si es un ng serve de prueba
        if(!this.css_external){
          const style = this.renderer.createElement('style');
          style.type = "text/css";
          style.innerHTML = css;
          style.id = 'css_external';
          this.renderer.appendChild(this.doc.head, style);
          this.css_external = style;
        }
      }
    }
  }

  headersBuilder(auth?: boolean, body?: string) {

    let headers: HttpHeaders;

    if(auth)
      headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': `${ this.getStorage('auth_token')}`,
        'commerceid': `${this.website.id}`,
      })

    else
      headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'commerceid': `${this.website.id}`,
      })

    let requestOptions;

    if(body)
      requestOptions = {
        headers: headers,
        body: body,
        withCredentials: true
      }

    else
      requestOptions = {
        headers: headers,
        withCredentials: true
      }

    requestOptions

    return requestOptions;
  }

  call(number:string){
    window.open('tel:'+number,'_system');
  }

  navigate(url, params?){

    if(this.isBrowser){

      if(environment.production && environment.name == '(PROD)'){

        url = url.join('/');
        const listParams = [];

        if(params){
          for(let key of Object.keys(params)){
            listParams.push(`${key}=${params[key]}`);
          }
        }

        if(listParams.length){
          url = url + '?' + listParams.join('&');
        }

        window.location.href = url;

      }else{

        this._router.navigate( url , { "queryParams": params });

      }

    }else{

      this._router.navigate( url , { "queryParams": params });

    }

  }

  openUrl(url){
    if(this.isMobile()){
      window.open(url,'_system','location=yes');
    }else{
      window.open(url,'_blank');
    }
  }

  openEmail(email){
    window.open(`mailto:${email}`,'_system');
  }

  isMobile() {

    if(this.isBrowser){
      return window.matchMedia('screen and (max-width: 1080px)').matches;
    }else{
      return !this._deviceService.isDesktop();
    }

  }


  setStorage(key: string, value: any) {
    try {
      //this.ls.set(key)
      localStorage.setItem(key, value)
    }
    catch(e) { }
  }


  getStorage(key: string) {
    try {
      //return this.ls.get(key)
      return localStorage.getItem(key)
    }
    catch(e) {
      return null
    }
  }


  removeStorage(key: string, all?: boolean) {
    try {
      //all ? this.ls.removeAll() : this.ls.remove(key)
      all ? localStorage.clear() : localStorage.removeItem(key)
    }
    catch(e) { }
  }

  setState(key:string, data:any){
    this._state.set(makeStateKey(key) ,data);
  }

  getState(key:string, defaultValue?:any){
    return this._state.get(makeStateKey(key), defaultValue);
  }

  setFacebookData(data){

    this.updateMeta({property:'og:url',content: this.website.url+this._router.url}, 'property="og:url"')
    this.setPageDescription(data.description);

    let currency = this.website?.currency_title || 'ARS';

    this._meta.addTags([
      {property:'og:type', content: 'product'},
      {property:'og:price:amount',content: data.price},
      {property:'og:price:currency',content: currency},
      {property:'product:brand',content: data.brand},
      {property:'product:description', content: data.description},
      {property:'product:availability',content: data.stock},
      {property:'product:condition',content: data.condition},
      {property:'product:price:amount',content: data.price},
      {property:'product:price:currency',content: currency},
      {property:'product:retailer_item_id',content: data.hash},
      {property:'product:sale_price:amount',content: data.promo},
      {property:'product:sale_price:currency',content: currency},
    ]);

  }

  removeFacebookData(){
    this.updateMeta({property:'og:url',content: this.website.url}, 'property="og:url"')
    this._meta.removeTag("property = 'og:price:amount'");
    this._meta.removeTag("property = 'og:price:currency'");
    this._meta.removeTag("property = 'product:brand'");
    this._meta.removeTag("property = 'product:availability'");
    this._meta.removeTag("property = 'product:condition'");
    this._meta.removeTag("property = 'product:price:amount'");
    this._meta.removeTag("property = 'product:price:currency'");
    this._meta.removeTag("property = 'product:retailer_item_id'");
    this._meta.removeTag("property = 'product:sale_price:amount'");
    this._meta.removeTag("property = 'product:sale_price:currency'");
    this._meta.removeTag("property = 'product:description'");
  }


  formatNumber(num) {

    const {decimals, currency} = this.website;
    const number = parseFloat(num)
                .toFixed(decimals)
                .toString()
                .replace('.',',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    return currency + '' + number;

  }

  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }


  shuffleArray(array) {
    return array
    .map(a => [Math.random(), a])
    .sort((a, b) => a[0] - b[0])
    .map(a => a[1])
  }

  generateRandomId(){
    return (+new Date).toString(36);
  }


  setPageTitle(title) {
    this.setTitle(title)
    this.updateMeta({property: 'og:title', content: title}, 'property="og:title"')
    this.updateMeta({name: 'twitter:title', content: title}, 'name="twitter:title"')
  }


  setPageImage(image) {
    this.updateMeta({property: 'og:image', content: image}, 'property="og:image"')
    this.updateMeta({name: 'twitter:image', content: image}, 'name="twitter:image"')
  }


  setPageDescription(description) {
    this.updateMeta({name: 'description', content: description}, 'name="description"')
    this.updateMeta({property: 'og:description', content: description}, 'property="og:description"')
    this.updateMeta({name: 'twitter:description', content: description}, 'name="twitter:description"')
  }


  private setTitle(title) {
    this._title.setTitle(title)
  }


  private updateMeta(tag: MetaDefinition, attr) {
    this._meta.updateTag(tag, attr)
  }


  requireSignin() {
    this.loginBox = true;
    //this.signinRequired.emit(true)
  }

  showInstallmentsBox(price) {
    this.installmentsPrice = price;
    this.installmentsBox = true;
    //this.signinRequired.emit(true)
  }


  logout(next?) {

    // remove indicators
    this.favorites = null
    this.favoritesJson = null
    this.payments = null

    // remove session
    this.isLogged = false
    this.removeStorage(null, true)

    // profile session
    this.profile = undefined;

    // set next route
    if(!next) {
      this.nextUrl.url = null
      this.nextUrl.timestamp = null;
    } else {
      this.nextUrl.url = next
      this.nextUrl.timestamp = Math.floor(new Date().getTime() / 1000)
    }

    this.navigate(['/']);

  }


  addMassiveProductInCart(products, title?, price?){

    this.listProductInCart = {};

    for(let product of products){

      if(product.thumbnail){
        product.thumbnail = this.baseUrl + product.thumbnail;
      }else{
        product.thumbnail = this.emptyImage;
      }

      if(!this.listProductInCart[product.id]){
        product.quantity = product.gift_quantity;
        product.positionAdding = Object.keys(this.listProductInCart).length;
        this.listProductInCart[product.id] = product;
      }else{
        this.listProductInCart[product.id].quantity += product.gift_quantity;
      }

        //this.countShowOpenCartEmmiter++;
      product.total = this.totalPriceInCart;
    }

    this.setStorage('cart',JSON.stringify(this.listProductInCart));

  }


  selectMethodShipment(method){
    this.methodShipment = method;
    this.onSelectShipment.emit(method);
    this.setStorage('methodShipment',JSON.stringify(this.methodShipment));
  }

  showRegretBox(){
    this.regretBox = true;
  }

  //HTTP CLIENT
  getWebsiteInfo(hostname, draft_id?) {
    return this._http.get(`${ this.apiUrl }dynamic/website/hostname/${hostname}${draft_id?`?draft=${draft_id}`:''}`);
  }

  getMethodsInfoFooter(){
    let options = this.headersBuilder();
    return this._http.get(`${this.apiUrl}dynamic/website/footer`, options);
  }

  //OH SI, CODIGO SPAGUETI, ESTO TIENE QUE IR A UN SERVICIO APARTE
  getStockById(id, stock){
    let options = this.headersBuilder();
    return this._http.get(`${ this.apiUrl}gift/stock/id/${id}/stock/${stock}`, options);
  }

  getStockByCart(cart){
    let options = this.headersBuilder(false, cart);
    return this._http.post(`${ this.apiUrl}gift/stock/cart`, cart, options);
  }



}
