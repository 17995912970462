import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { concatMap, map, shareReplay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AnalyticsService } from './analytics.service';
import { Observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  url: string;

  products = {};

  weight: number = 0;
  volume: number = 0;
  quantityTotal: number = 0;

  subtotal: number = 0;
  subtotalFormat: string;

  total: number = 0;
  totalFormat: string;

  totalWithPaymentDiscount: number = 0;
  totalWithPaymentDiscountFormat: string;

  country: number;
  provincies: any[] = [];
  zip: string;

  requireShipmentInfo: boolean = false;

  methodsPaymentAvailables: any = [];
  methodPayment: any;

  methodPaymentDiscount: any;

  methodsShipmentAvailables: any = [];
  methodShipment: any;

  userPayment: any;
  userShipment: any;

  couponDiscount: any;

  discounts: any = [];
  pendingDiscountTitle: string;

  transactionId: number = null;
  transactionHash: string = null;

  assignmentInProgress: boolean = false;

  $onChange: EventEmitter<any> = new EventEmitter();
  $openCart: EventEmitter<boolean> = new EventEmitter();

  templateId: number;

  messageError: {
    status: boolean;
    message: string;
    products: any[];
  };

  // Cola de peticiones para respetar el orden de las peticiones
  private requestQueue = new Subject<() => Observable<any>>();

  constructor(
    private _http: HttpClient,
    private _globalService: GlobalService,
    private _analyticsService: AnalyticsService
  ) {
    this.url = `${this._globalService.apiUrl}cart`;
    this.country = this._globalService.website.country_id;

    if (this._globalService.isBrowser && environment.name == '(PROD)') {
      const cart = document.getElementById('cart-info') as HTMLScriptElement;

      if (cart && cart.type === 'application/json') {
        try {
          // Parsea el texto del script a JSON
          if (!cart.innerText) {
            return;
          }
          const data = JSON.parse(cart.innerText);
          this.applyCart(data);
        } catch (error) {}
      } else {
      }
    } else if (
      (this._globalService.isBrowser && environment.name == '(STAGING)') ||
      !environment.production
    ) {
      console.log(' Estoy en modo dev, debo obtener el carrito');
      this.getCart();
    }

    //if(this._globalService.isServer || !environment.production){
    //  this.getCart();
    //}else if(!this.subtotal && !this._globalService.isLogged){
    //  this.deleteCookieSession();
    //}

    this.requestQueue.pipe(concatMap((request) => request())).subscribe({
      next: (response) => console.log('Respuesta procesada:', response),
      error: (err) => console.error('Error en la petición:', err),
    });
  }

  // Método para agregar una petición a la cola
  private enqueueRequest(request: () => Observable<any>) {
    this.requestQueue.next(request);
  }

  getCart() {
    const options = this._globalService.headersBuilder(false);
    this._http.get(`${this.url}`, options).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.applyCart(res.data);
        }
      },
      () => {},
      () => {}
    );
  }

  validCart() {
    const options = this._globalService.headersBuilder(false);
    return this._http.get(`${this.url}/valid`, options).pipe(
      map((res: any) => {
        if (res.status == 200) {
          this.products = { ...res.data.products };
          this.subtotal = res.data.subtotal;
          this.subtotalFormat = res.data.subtotalFormat;
          this.total = res.data.total;
          this.totalFormat = res.data.totalFormat;
          this.totalWithPaymentDiscount = res.data.totalWithPaymentDiscount;
          this.totalWithPaymentDiscountFormat =
            res.data.totalWithPaymentDiscountFormat || 0;
          this.methodPaymentDiscount = res.data.methodPaymentDiscount;
          this.discounts = res.data.discounts;
        }

        return res;
      })
    );
  }

  applyCart(data) {
    this.products = data.products;
    this.weight = data.weight;
    this.volume = data.volume;
    this.quantityTotal = data.quantityTotal;
    this.subtotal = data.subtotal;
    this.subtotalFormat = data.subtotalFormat;

    this.total = data.total;
    this.totalFormat = data.totalFormat;
    this.totalWithPaymentDiscount = data.totalWithPaymentDiscount;
    this.totalWithPaymentDiscountFormat = data.totalWithPaymentDiscountFormat;
    this.methodPaymentDiscount = data.methodPaymentDiscount;

    this.methodsShipmentAvailables = data.methodsShipmentAvailables;
    this.zip = data.zip;
    this.methodShipment = data.methodShipment;
    this.methodPayment = data.methodPayment;
    this.userShipment = data.userShipment;
    this.userPayment = data.userPayment;
    this.transactionId = data.transactionId;
    this.couponDiscount = data.couponDiscount;
    this.requireShipmentInfo = data.requireShipmentInfo;
    this.discounts = data.discounts;
    this.pendingDiscountTitle = data.pendingDiscountTitle;
    this.$onChange.emit();
  }

  recoverCartByHash(hash: string) {
    const options = this._globalService.headersBuilder(false);
    return this._http.get(`${this.url}/recovery/hash/${hash}`, options).pipe(
      map((res: any) => {
        if (res.status == 200) {
          this.applyCart(res.data);
          // this._globalService.onUpdateListShipment.emit(true); //La idea es que refresque de nuevo
          this.$onChange.emit();
        }
        return res;
      })
    );
  }

  getCartByTransactionHash(hash: string) {
    const options = this._globalService.headersBuilder(false);
    return this._http.get(`${this.url}/hash/${hash}`, options).pipe(
      map((res: any) => {
        if (res.status == 200) {
          this.products = res.data.products;
          this.quantityTotal = res.data.quantityTotal;
          this.methodPayment = res.data.methodPayment;
          this.methodShipment = res.data.methodShipment;
          this.userPayment = res.data.userPayment;
          this.userShipment = res.data.userShipment;
          this.couponDiscount = res.data.couponDiscount;
          this.discounts = res.data.discounts;
          this.total = res.data.total;
          this.totalFormat = res.data.totalFormat;
        }
        return res;
      })
    );
  }

  getProductsCartByTransactionId(transactionId: number) {
    const options = this._globalService.headersBuilder(false);
    return this._http.get(
      `${this.url}/transactionid/${transactionId}`,
      options
    );
  }

  copyCartByTransactionId(transactionId: number) {
    const options = this._globalService.headersBuilder(false);
    return this._http
      .get(`${this.url}/transactionid/${transactionId}?copy=true`, options)
      .pipe(
        map((res: any) => {
          if (res.status == 200) {
            this.products = res.data.products;

            this.quantityTotal = res.data.quantityTotal;
            this.weight = res.data.weight;
            this.volume = res.data.volume;
            this.subtotal = res.data.subtotal;
            this.subtotalFormat = res.data.subtotalFormat;
            this.total = res.data.total;
            this.totalFormat = res.data.totalFormat;
            // this.totalWithPaymentDiscount = res.data.totalWithPaymentDiscount;
            // this.totalWithPaymentDiscountFormat = res.data.totalWithPaymentDiscountFormat || 0;
            // this.methodPaymentDiscount = res.data.methodPaymentDiscount;
            this.requireShipmentInfo = res.data.requireShipmentInfo;
            this.zip = res.data.zip;
            this.discounts = res.data.discounts;

            this.couponDiscount = null;
            this.methodsShipmentAvailables = [];

            this._globalService.onUpdateListShipment.emit(true); //La idea es que refresque de nuevo
            this.$onChange.emit();

            if (Object.keys(this.products).length) {
              this._globalService.openCart.emit(true);
            }
          }
          return res;
        })
      );
  }

  updateCart(
    productId: string | number,
    quantity: number,
    openCart: boolean = false
  ): Observable<any> {
    const body = JSON.stringify({ id: productId, quantity });
    const options = this._globalService.headersBuilder(false, body);
    return new Observable((observer) => {
      this.enqueueRequest(() =>
        this._http.post(`${this.url}/product`, body, options).pipe(
          map((res: any) => {
            if (res.status == 200) {
              const { event_id } = res.data;

              if (res.data.products[productId]) {
                if (this.products[productId]) {
                  for (let key in res.data.products[productId]) {
                    if (
                      this.products[productId][key] !=
                      res.data.products[productId][key]
                    ) {
                      this.products[productId][key] =
                        res.data.products[productId][key];
                    }
                  }
                } else {
                  this.products[productId] = res.data.products[productId];
                }

                this._analyticsService.trackEvent({
                  event: 'add_to_cart',
                  data: Object.assign(
                    {},
                    {
                      ...this.products[productId],
                      id: this.products[productId].gift_id,
                    }
                  ),
                  event_id,
                });
              } else {
                this._analyticsService.trackEvent({
                  event: 'remove_from_cart',
                  data: { id: productId },
                  event_id,
                });

                delete this.products[productId];
              }

              this.products = { ...this.products };

              this.quantityTotal = res.data.quantityTotal;
              this.weight = res.data.weight;
              this.volume = res.data.volume;
              this.subtotal = res.data.subtotal;
              this.subtotalFormat = res.data.subtotalFormat;
              this.total = res.data.total;
              this.totalFormat = res.data.totalFormat;
              this.totalWithPaymentDiscount = res.data.totalWithPaymentDiscount;
              this.totalWithPaymentDiscountFormat =
                res.data.totalWithPaymentDiscountFormat || 0;
              this.methodPaymentDiscount = res.data.methodPaymentDiscount;
              this.requireShipmentInfo = res.data.requireShipmentInfo;
              this.zip = res.data.zip;
              this.discounts = res.data.discounts;
              this.pendingDiscountTitle = res.data.pendingDiscountTitle;

              this.couponDiscount = null;
              this.methodsShipmentAvailables = [];
              this._globalService.onUpdateListShipment.emit(true);
              this._globalService.openCart.emit(openCart);
              this.$onChange.emit();

              if (!this.subtotal) {
                this.deleteCookieSession();
              }
            }

            observer.next(res);
            observer.complete();
            return res;
          })
        )
      );
    });
  }

  checkCartStock() {
    const options = this._globalService.headersBuilder(false);
    return this._http.get(`${this.url}/stock`, options).pipe(
      map((res: any) => {
        if (res.status == 200) {
          const { event_id } = res.data;
          this._analyticsService.trackEvent({
            event: 'begin_checkout',
            data: { value: this.total },
            event_id,
          });
        }
        return res;
      })
    );
  }

  finishCart(body) {
    body = JSON.stringify(body);
    const options = this._globalService.headersBuilder(false, body);
    return this._http.post(`${this.url}/finish`, body, options);
  }

  finishCartWhatsapp() {
    const body = JSON.stringify({ userShipment: this.userShipment });
    const options = this._globalService.headersBuilder(false, body);
    return this._http.post(`${this.url}/ws/finish`, body, options);
  }

  getMethodPayments() {
    const options = this._globalService.headersBuilder(false);
    return this._http.get(`${this.url}/method/payment`, options).pipe(
      map((res: any) => {
        if (res.status == 200) {
          this.methodsPaymentAvailables = res.data;
        }
        return res;
      })
    );
  }

  getMethodShipments() {
    const options = this._globalService.headersBuilder(false);
    return this._http.get(`${this.url}/method/shipment`, options).pipe(
      map((res: any) => {
        if (res.status == 200) {
          this.methodsShipmentAvailables = res.data;
        }
        return res;
      })
    );
  }

  assignZip(zip: string): Observable<any>{

    return new Observable((observer) => {
      this.enqueueRequest(() => {
      const body = JSON.stringify({
        zip,
        preselectMethodId: this.methodShipment?.id,
      });

      const options = this._globalService.headersBuilder(false, body);
      this.assignmentInProgress = true;

      return this._http.put(`${this.url}/zip`, body, options).pipe(
        map((res: any) => {
        if (res.status == 200) {
          this.zip = res.data.zip;
          this.methodsShipmentAvailables = res.data.methodsShipmentAvailables;
          this.methodShipment = res.data.methodShipment;
          this.couponDiscount = res.data.couponDiscount;
          this.userShipment = res.data.userShipment;

          this.total = res.data.total;
          this.totalFormat = res.data.totalFormat;
          this.totalWithPaymentDiscount = res.data.totalWithPaymentDiscount;
          this.totalWithPaymentDiscountFormat =
          res.data.totalWithPaymentDiscountFormat || 0;
          this.methodPaymentDiscount = res.data.methodPaymentDiscount;

          this.$onChange.emit();
        }

        this.assignmentInProgress = false;

        observer.next(res);
        observer.complete();
        return res;
        })
      );
      });
    });
  }

  calculateShipment(zip: number, productId: number, quantity: number) {
    const body = JSON.stringify({
      zip,
      productId,
      quantity,
    });

    const options = this._globalService.headersBuilder(false, body);

    return this._http.put(`${this.url}/shipment/calculate`, body, options).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  selectMethodShipment(id: number) {
    const body = JSON.stringify({ id });
    const options = this._globalService.headersBuilder(false, body);
    return this._http.put(`${this.url}/method/shipment`, body, options).pipe(
      map((res: any) => {
        if (res.status == 200) {
          const { event_id } = res.data;

          this.total = res.data.total;
          this.totalFormat = res.data.totalFormat;
          this.totalWithPaymentDiscount = res.data.totalWithPaymentDiscount;
          this.totalWithPaymentDiscountFormat =
            res.data.totalWithPaymentDiscountFormat || 0;
          this.methodPaymentDiscount = res.data.methodPaymentDiscount;
          this.methodShipment = res.data.methodShipment;
          this.couponDiscount = res.data.couponDiscount;

          this.$onChange.emit();
          this._analyticsService.trackEvent({
            event: 'add_shipping_info',
            data: { shipment_info: this.methodShipment.title },
            event_id,
          });
        }

        return res;
      })
    );
  }

  selectMethodPayment(id: number) {
    const body = JSON.stringify({ id });
    const options = this._globalService.headersBuilder(false, body);
    return this._http.put(`${this.url}/method/payment`, body, options).pipe(
      map((res: any) => {
        if (res.status == 200) {
          const { event_id } = res.data;

          this.total = res.data.total;
          this.totalFormat = res.data.totalFormat;
          this.methodPayment = res.data.methodPayment;
          this.$onChange.emit();

          this._analyticsService.trackEvent({
            event: 'add_payment_info',
            data: { payment_info: this.methodPayment.title },
            event_id,
          });
        }
        return res;
      })
    );
  }

  assingUserContact(form: any) {
    const body = JSON.stringify(form);
    const options = this._globalService.headersBuilder(false, body);
    return this._http.put(`${this.url}/user/contact`, body, options).pipe(
      map((res: any) => {
        if (res.status == 200) {
          this.userPayment = res.data.userPayment;
          this.transactionId = res.data.transactionId;
          this.$onChange.emit();
          //this.total = res.data.total;
          //this.totalFormat = res.data.totalFormat;
          //this.methodShipment = res.data.methodShipment;
        }

        return res;
      })
    );
  }

  assignUserData(form: any) {
    const body = JSON.stringify(form);
    const options = this._globalService.headersBuilder(false, body);
    return this._http.put(`${this.url}/user/data`, body, options).pipe(
      map((res: any) => {
        if (res.status == 200) {
          this.userPayment = res.data.userPayment;
          this.userShipment = res.data.userShipment;
          this.$onChange.emit();
        }
        return res;
      })
    );
  }

  assignUserPayment(form: any) {
    const body = JSON.stringify(form);
    const options = this._globalService.headersBuilder(false, body);
    return this._http.put(`${this.url}/user/payment`, body, options).pipe(
      map((res: any) => {
        if (res.status == 200) {
          this.userPayment = res.data.userPayment;
          this.$onChange.emit();
        }
        return res;
      })
    );
  }

  assignUserShipment(form: any) {
    const body = JSON.stringify(form);
    const options = this._globalService.headersBuilder(false, body);
    return this._http.put(`${this.url}/user/shipment`, body, options).pipe(
      map((res: any) => {
        if (res.status == 200) {
          this.userShipment = res.data.userShipment;
          this.$onChange.emit();
        }
        return res;
      })
    );
  }

  assignCouponCode(form: any) {
    const body = JSON.stringify(form);
    const options = this._globalService.headersBuilder(false, body);

    return this._http.put(`${this.url}/coupon`, body, options).pipe(
      map((res: any) => {
        if (res.status == 200) {
          this.total = res.data.total;
          this.totalFormat = res.data.totalFormat;
          // this.totalWithPaymentDiscount = res.data.totalWithPaymentDiscount;
          // this.totalWithPaymentDiscountFormat = res.data.totalWithPaymentDiscountFormat || 0;
          // this.methodPaymentDiscount = res.data.methodPaymentDiscount;
          this.couponDiscount = res.data.couponDiscount;
        }

        return res;
      })
    );
  }

  getProvincies() {
    if (this.provincies?.length) {
      return of(this.provincies);
    } else {
      const country = this._globalService.website.country_id;
      const options = this._globalService.headersBuilder(false);
      return this._http
        .get(`${this.url}/provincies/country/${country}`, options)
        .pipe(
          map((res: any) => {
            this.provincies = res.data;
            return this.provincies;
          })
        );
    }
  }

  // Preferencias
  setPreferenceMessage(message: string) {
    this._globalService.setStorage('preferenceForm', message);
  }

  getPreferenceMessage() {
    return this._globalService.getStorage('preferenceForm');
  }

  deleteCookieSession() {
    if (this._globalService.isBrowser) {
      //var date = new Date();
      //date.setTime(date.getTime() - 1);
      //document.cookie = 'TIENDA_NEGOCIO' + "=; max-age=" + 0 + "; path=/";
    }
  }

  //AGREGAR COMPROBANTE DE PAGO
  postVoucher(file: File, hash: string) {
    
    const formData = new FormData();
    
    formData.append('hash', hash);
    formData.append('file', file);

    const headers = new HttpHeaders({
      Authorization: `${this._globalService.getStorage('auth_token')}`,
      commerceid: `${this._globalService.website.id}`,
    });

    const options = {
      headers,
      withCredentials: true,
    };

    return this._http.post(`${this.url}/receipt`, formData, options);

  }
}
